import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const Login = () => {
    // Global
    const { setIsLoading } = useAuth();

    // Connect Social
    const loginSocial = async (type) => {
        // Loader
        setIsLoading(true);

        await axios.get(`/users/token/social/redirect?type=${type}`)
            .then(response => {
                setIsLoading(false);
                window.location.href = response.data.redirect;
            }).catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">Se connecter</span>

                {/* <button className="login-submit facebook" onClick={() => loginSocial('facebook') }>
                    <div className='social-icon'>
                        <img src="/images/facebook-icon.svg" alt="Facebook"></img>
                    </div>
                    
                    <div className='social-text'>
                        <span>Continuer avec Facebook</span>
                    </div>
                </button> */}

                <button className="login-submit google" onClick={() => loginSocial('google')}>
                    <div className='social-text'>
                        <span>Continuer avec Google</span>
                    </div>

                    <div className='social-icon'>
                        <img src="/images/google-icon.svg" alt="Google"></img>
                    </div>
                </button>

                {/* <button className="login-submit apple" onClick={() => loginSocial('apple')}>
                    <div className='social-icon'>
                        <img src="/images/apple-icon.svg" alt="Apple"></img>
                    </div>

                    <div className='social-text'>
                        <span>Continuer avec Apple</span>
                    </div>
                </button> */}

                <hr data-content="ou"/>

                <Link to="/login">
                    <button className="login-submit">Se connecter avec un email</button>
                </Link>
                <span className="login-new">Pas encore inscrit ?&nbsp;<Link to="/register" className="text-decoration-underline">Créer un compte</Link></span>
            </div>
        </>
    );
};

export default Login;
