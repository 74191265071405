import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import InputField from '../InputField';

const LoginKids = () => {
    // Global
    const [, setCookie] = useCookies();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refUsername, ...inputPropsUsername } = register('username', {
        required: 'Champ Adresse email obligatoire',
    });

    // Call Login Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axios.post('/users/login/kids', {
                familyCode: data.username,
            });

            // Data
            let token = response.data.token;
            let refreshToken = response.data.refresh_token;
            let jwtDecode = jwt_decode(token);
            let username = jwtDecode.username;
            let lastName = jwtDecode.last_name;
            let firstName = jwtDecode.first_name;
            let roles = jwtDecode.roles;
            let jabuPoint = jwtDecode.jabu_point;
            let canteenId = jwtDecode.canteen_id;
            let childId = jwtDecode.user_child_id;
            //let lastLoggedOn = jwtDecode.last_logged_on;

            setAuth({ username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId }, { path: '/' });

            if (childId !== null || canteenId !== null) {
                const response = await axios.get('/guests/canteen', {
                    params: {
                        childId: childId,
                    },
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                    withCredentials: true
                });
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {
                    id: response.data.canteen.id,
                    name: response.data.canteen.name
                }, {path: '/'})
            }

            if (childId !== null) {
                const response = await axios.get('/guests/child', {headers:{'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}, withCredentials: true});
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: response.data.child.id, name: response.data.child.firstName, point: response.data.child.jabuPoint}, { path: '/' })
            }

            /* await axios.put('/guests/last_logged', {
                userId: jwtDecode.user_id,
            }, { headers: {Authorization: `Bearer ${token}`} });

            if( null == lastLoggedOn ){
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, { path: '/' });
                navigate('/tutorial');
            }else{
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, { path: '/' });
                navigate('/');
            }*/

            window.location = '/';
        } catch (err) {
            setFlash({ message: 'Code incorrect' });
            setShowFlash(true);
        }

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">Se connecter</span>
                <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                    <InputField color="#94928B" label="Code famille" className="login-field" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                    
                    <button className="login-submit" type="submit">Se connecter</button>
                </form>

                <div className="login-subtitle" style={{textAlign: 'center', marginTop: '20px'}}>
                    Pas de code famille ?<br/>Demande à tes parents de <Link to="/register" className="text-decoration-underline">s&apos;inscrire</Link>
                </div>
            </div>
        </>
    );
};

export default LoginKids;
