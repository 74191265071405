import React, {useEffect, useState} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InputField from '../../components/InputField';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';

const UserUpdateChild = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { auth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [displayChild, setDisplayChild] = useState(false);
    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    const location = useLocation();
    const childId = location?.state?.childId ?? '';
    const [, setCookie] = useCookies();

    const {refFirstName, ...inputPropsFirstName} = register('firstName', {
        required: 'Prénom obligatoire',
    });
    const {refLastName, ...inputPropsLastName} = register('lastName', {
        required: 'Nom obligatoire',
    });
    const {refCode, ...inputPropsCode} = register('code', {
        required: 'Code obligatoire',
    });

    useEffect(() => {
        // Redirect if not choice
        if (auth?.childId === null) {
            navigate('/my-profile');
        } else {
            getChild();
        }
    }, []);

    const getChild = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests/child', {
            withCredentials: true,
            params: {
                childId: childId,
            }
        })
        reset({
            firstName: result.data.child.firstName,
            lastName: result.data.child.lastName,
            code: result.data.code,
        })
        setDisplayChild(true);
        setIsLoading(false);
    };

    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axiosPrivate.put('/guests/child', {
                firstName: data.firstName,
                lastName: data.lastName,
                code: data.code,
                childId: childId,
            }, {withCredentials: true});

            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': response.data.child.id, 'name': response.data.child.firstName, 'point': response.data.child.jabuPoint}, { path: '/' })
            setFlash({ message: 'L\'enfant a été modifié', type: 'success' });
            navigate('/my-family');
        } catch (err) {
            let error;
            if (err?.response?.status === 404) {
                error = 'Le code Restaurant est incorrect';
            } else if (err?.response?.status === 409) {
                error = 'Un enfant existe déjà avec ce code restaurant';
            } else {
                error = 'Erreur Serveur';
                console.log(err)
                navigate('/500');
            }
            setFlash({message: error});
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            { displayChild &&
                <div className='profil-page d-flex min-vh-100 justify-content-center'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-family'>
                                    <span style={{borderRadius: '20px', padding: '10px' }}>
                                        <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                        </div>

                        <div className='d-flex flex-column profile-block'>
                            <div className='user-title d-flex justify-content-center'>
                                Mon enfant
                            </div>

                            <div className='d-flex flex-column align-items-center'>
                                <span><em>Modifier mon enfant</em></span>
                                <form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit(submit)}>
                                    <InputField
                                        color="#000"
                                        label="Prénom de l'enfant *"
                                        className="user-input"
                                        error={!!errors.firstName}
                                        helper={errors?.firstName?.message}
                                        inputRef={refFirstName}
                                        inputProps={inputPropsFirstName}
                                    ></InputField>
                                    <InputField
                                        color="#000"
                                        label="Nom de l'enfant *"
                                        className="user-input"
                                        error={!!errors.lastName}
                                        helper={errors?.lastName?.message}
                                        inputRef={refLastName}
                                        inputProps={inputPropsLastName}
                                    ></InputField>
                                    <InputField
                                        color="#000"
                                        label="Code Restaurant *"
                                        className="user-input"
                                        error={!!errors.code}
                                        helper={errors?.code?.message}
                                        inputRef={refCode}
                                        inputProps={inputPropsCode}
                                    ></InputField>
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                        <input className='user-submit' type='submit' value='Valider'></input>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserUpdateChild;
