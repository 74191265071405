import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InputField from '../../components/InputField';
import useQRCodeScan from '../../hooks/useQRCodeScan';
import { useCookies } from 'react-cookie';

const UserAddCanteen = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');


    // Global
    const axiosPrivate = useAxiosPrivate();
    const [canteensList, setCanteensList] = useState();
    const [displayAdd, setDisplayAdd] = useState(false);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [timeOut, setTimeOut] = useState(1000);
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [startQrCode, decodedQRData] = useQRCodeScan();
    const [, setCookie] = useCookies({});

    // Form
    const { refSearch, ...inputPropsSearch } = {
        onChange: (e) => {
            setSearch(e.target.value)
        },
    };

    // Get Canteens
    const getCanteens = async() => {
        setIsLoading(true);
        setDisplayAdd(false);
        const result = await axiosPrivate.get(`/guests/canteen/search?name=${search}`, { withCredentials: true});
        setCanteensList(result.data.canteens);
        setDisplayAdd(true);
        setIsLoading(false);

        setTimeOut(1000);
    }

    // Open QR Code Scan
    const openQRCodeScan = () => {
        startQrCode();
    };

    useEffect(() => {
        // Redirect if not choice
        if (auth?.canteenId === null) {
            navigate('/my-profile');
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (search.length >= 2) {
                getCanteens()
            }
        }, timeOut);

        return () => clearTimeout(timer);
    }, [search]);

    useEffect(() => {
        // Set QR Code Data if Scan Success
        if (decodedQRData?.data) {
            addCanteen(decodedQRData.data);
        }
    }, [decodedQRData]);

    const addCanteen = async (code) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const result = await axiosPrivate.post('/guests/canteen', {
                canteen_code: code,
            }, {withCredentials: true});

            setAuth({ ...auth, canteenId: result.data.defaultCanteen.id });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, canteenId: result.data.defaultCanteen.id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {'id': result.data.defaultCanteen.id, 'name': result.data.defaultCanteen.name})
            setFlash({ message: 'Le restaurant a été ajouté', type: 'success' });
            navigate('/my-canteens');
        } catch (err) {
            console.log(err);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <div className='profil-page d-flex min-vh-100 justify-content-center'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                    <div className='d-flex profile-header-back'>
                        <Link to='/my-canteens'>
                            <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                            </span>
                        </Link>
                    </div>
                    <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                </div>

                <div className='d-flex flex-column profile-block'>
                    <div className='user-title d-flex justify-content-center'>
                        Mon restaurant
                    </div>

                    <div className='d-flex flex-column align-items-center'>
                        <span><em>Nouveau restaurant</em></span>
                        <InputField color="#000" label="Rechercher un restaurant" className="user-input" inputRef={refSearch} inputProps={inputPropsSearch}></InputField>
                        <div id="qrcode-scan"></div>
                        <span className="d-none text-center">Ou Flasher le QR Code de votre restaurant</span>
                        <div className="d-none qr-code text-center mt-2">
                            <img role="presentation" className="w-50" onClick={() => openQRCodeScan()} alt="QR Code" src="/images/qr-code-search-canteen.png" />
                        </div>
                        { displayAdd &&
                            <div className="w-100 mt-3">
                                { canteensList.length > 0 ?
                                    canteensList.map((canteen) => (
                                        <div className="canteen-block" key={canteen.id}>
                                            <p>Nom: {canteen.name}</p>
                                            <p>Adresse: {canteen.address}</p>
                                            <p>Code Postal: {canteen.zip}</p>
                                            <p>Ville: {canteen.city}</p>
                                            <button className='user-submit w-100' onClick={() => addCanteen(canteen.code)}>
                                                Ajouter le restaurant
                                            </button>
                                        </div>
                                    )) :
                                    <div className="text-center"><span>Pas de restaurant trouvé</span></div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAddCanteen;
