import React from 'react';
import { Link } from 'react-router-dom';

const LoginTemplate = ({ child, useCase='login' }) => {
    return (
        <>
            <div className="login-template min-vh-100">
                <div className="d-flex col-12 justify-content-center align-items-center flex-column">
                    <span>
                        <img style={{width:'200px'}} alt="" src="/images/logo.png"></img>
                    </span>

                    <div className='d-flex w-100 mt-4'>
                        <Link to='/login' className='w-100'>
                            <div className={`d-flex justify-content-center ${useCase == 'login' || useCase == 'login-kids' ? 'login-active' : ''}`}>Se connecter</div>
                        </Link>

                        {/* <Link to='/login/kids' className='w-100'>
                            <div className={`d-flex justify-content-center ${useCase == 'login-kids' ? 'login-active' : ''}`}>
                                <span className="material-symbols-outlined">
                                    escalator_warning
                                </span>
                            </div>
                        </Link> */}
                        
                        <Link to='/register' className='w-100'>
                            <div className={`d-flex justify-content-center ${useCase == 'register' ? 'login-active' : ''}`}>S&apos;inscrire</div>
                        </Link>
                    </div>

                    { child }
                </div>
            </div>

            {/*<div className="desktop-login-template">
                <img alt="Sample" src="/images/login.png"></img>
            </div>*/}
        </>
    );
};

export default LoginTemplate;
