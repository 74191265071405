import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import InputField from '../InputField';

const ResetPassword = () => {
    // Global
    const navigate = useNavigate();
    const { email, token } = useParams();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refPassword, ...inputPropsPassword } = register('password', {
        required: 'Mot de passe obligatoire',
        minLength: {
            value: 8,
            message: 'Minimum 8 caractères',
        },
    });
    const { refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || 'Mot de passe non identique',
    });

    // Call Reset Password Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axios.put('/users/password', {
                email: email,
                token: token,
                password: data.password,
            }, { withCredentials: true });

            setFlash({ message: 'Votre mot de passe est bien réinitialisé', type: 'success' });
            navigate('/login');
        } catch (err) {
            let error;
            let redirect = '/login';
            if (err?.response?.status === 400) {
                error = 'Le Token est expiré';
            } else if (err?.response?.status === 404) {
                error = 'Le Token est incorrect';
            } else {
                error = 'Erreur Serveur';
                redirect = '/500';
            }
            setFlash({ message: error });
            navigate(redirect);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Check User / Token
    const checkToken = async() => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            let url = `/users/password/token?email=${email}&token=${token}`;
            await axios.get(url, { withCredentials: true });

        } catch (err) {
            let error;
            let redirect = '/login';
            if (err?.response?.status === 400) {
                error = 'Le Token est expiré';
            } else if (err?.response?.status === 404) {
                error = 'Le Token est incorrect';
            } else {
                error = 'Erreur Serveur';
                redirect = '/500';
            }
            setFlash({ message: error });
            setShowFlash(true);
            navigate(redirect);
        }

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        checkToken();
    }, [])

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">Réinitialisation de votre mot de passe</span>

                <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) }>
                    <InputField color="#94928B" label="Mot de passe" type="password" className="login-field" error={!!errors.password} helper={errors?.password?.message} inputRef={refPassword} inputProps={inputPropsPassword}></InputField>
                    <InputField color="#94928B" label="Confirme ton mot de passe" type="password" className="login-field" error={!!errors.repeatPassword} helper={errors?.repeatPassword?.message} inputRef={refRepeatPassword} inputProps={inputPropsRepeatPassword}></InputField>
                    <button className="login-submit" type="submit">Réinitialiser mon mot de passe</button>
                </form>

                <div className="login-back text-center">
                    <span><Link to="/login">&lt;&lt; Retour</Link></span>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
